import "./App.css";
import MedicalFacilityForm from "./Components/MedicalFacilityForm";

function App() {
  return (
    <>
      <MedicalFacilityForm />
    </>
  );
}

export default App;
