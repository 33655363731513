import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";

// const url = "http://localhost:3001";
const url = "https://api.solutionsforpharma.in";

const SalesForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [autofilledData, setAutofilledData] = useState(null);
  const [agent, setAgent] = useState({ id: null, set: true });
  const [ledger, setLedger] = useState({ id: null, set: true });
  const [disabled, setDisabled] = useState(false);
  // Initial Values
  const initialValues = {
    agent_name: "",
    state: "",
    district: "",
    mobile_no: "",
    ledger_name: "",
    address: "",
    pin_code: "",
    contact_person: "",
    ledger_mobileno: "",
    fac_type: "",
    ledger_district: "",
    ledger_state: "",
    ledger_id: "",
    agent_id: "",
    sale_date: "",
    visits: "",
    pob: "",
    secondary_sale: "",
  };

  // Validation Schema
  const validationSchema = Yup.object({
    agent_name: Yup.string(),
    state: Yup.string(),
    district: Yup.string(),
    mobile_no: Yup.string().required("Mobile number is required"),
    ledger_name: Yup.string(),
    address: Yup.string(),
    pin_code: Yup.string(),
    contact_person: Yup.string(),
    ledger_mobileno: Yup.string().required("Ledger mobile number is required"),
    fac_type: Yup.string().required("Facility type is required"),
    ledger_district: Yup.string(),
    ledger_state: Yup.string(),
    sale_date: Yup.date(),
    visits: Yup.number(),
    pob: Yup.number().required("POB is required"),
    secondary_sale: Yup.number(),
  });

  const handleAutofill = async (mobileNumber, type) => {
    try {
      if (type === "mobile_no") {
        setDisabled(true);
        const data = await axios.post(`${url}/sunil/get-agent`, { mobile_no: mobileNumber, autofill: true });
        const { response, responseCode } = data.data;
        setDisabled(false);
        if (responseCode == true) {
          setAutofilledData(response);
          setAgent({ id: response.AgentID, set: false });
          return response;
        }
      }
      if (type === "ledger_mobileno") {
        setDisabled(true);
        const data = await axios.post(`${url}/sunil/get-ledger`, { ledger_mobileno: mobileNumber, autofill: true });
        const { response, responseCode } = data.data;
        setDisabled(false);
        if (responseCode == true) {
          setAutofilledData(response);
          setLedger({ id: response.LedgerID, set: false });
          return response;
        }
      }
    } catch (error) {
      console.error("Autofill error:", error);
      return null;
    }
  };
  const handleSubmit = async (values, { resetForm }) => {
    setIsSubmitting(true);
    try {
      let agentId = agent.id;
      let ledgerId = ledger.id;
      console.log(agent.set);
      if (agent.set === true) {
        const agentData = {
          mobile_no: values.mobile_no,
          state: values.state,
          district: values.district,
          agent_name: values.agent_name,
          autofill: false,
        };
        const data = await axios.post(`${url}/sunil/get-agent`, agentData);
        const { response, responseCode } = data.data;
        if (responseCode == true) {
          agentId = response.agentId;
        }
      }
      if (ledger.set === true) {
        const ledgerData = {
          ledger_name: values.ledger_name,
          address: values.address,
          pin_code: values.pin_code,
          contact_person: values.contact_person,
          ledger_mobileno: values.ledger_mobileno,
          fac_type: values.fac_type,
          ledger_district: values.ledger_district,
          ledger_state: values.ledger_state,
        };
        const data = await axios.post(`${url}/sunil/get-ledger`, ledgerData);
        const { response, responseCode } = data.data;
        if (responseCode == true) {
          ledgerId = response.agentId;
        }
      }
      const saleDate = {
        ledger_id: ledgerId,
        agent_id: agentId,
        sale_date: values.sale_date === "" ? null : values.sale_date,
        visits: values.visits,
        pob: values.pob,
        secondary_sale: values.secondary_sale,
      };
      const data = await axios.post(`${url}/sunil/add-sale`, saleDate);
      const { responseCode } = data.data;
      if (responseCode == true) {
        resetForm();
        alert("Sale Added.");
      }
    } catch (error) {
      console.error("Submission error:", error);
    } finally {
      setAgent({ id: null, set: true });
      setLedger({ id: null, set: true });
      setIsSubmitting(false);
    }
  };

  const FormInput = ({ name, label, type = "text", ...props }) => {
    const isMobileField = ["mobile_no", "ledger_mobileno"].includes(name);

    const handleMobileAutofill = async (event, formik) => {
      const value = event.target.value;
      if (isMobileField && value) {
        const autofilledData = await handleAutofill(value, name);

        if (autofilledData) {
          const fieldMappings = {
            mobile_no: {
              agent_name: autofilledData.AgentName || "",
              state: autofilledData.State || "",
              district: autofilledData.District || "",
            },
            ledger_mobileno: {
              ledger_name: autofilledData.LedgerName || "",
              address: autofilledData.Address || "",
              pin_code: autofilledData.Pincode || "",
              contact_person: autofilledData.ContactPerson || "",
              fac_type: autofilledData.Type || "",
              ledger_district: autofilledData.District || "",
              ledger_state: autofilledData.State || "",
            },
          };
          const mappingToUse = fieldMappings[name];
          if (mappingToUse) {
            const updatedValues = {
              ...formik.values,
              ...mappingToUse,
            };

            formik.setValues(updatedValues);
          }
        }
      }
    };

    return (
      <div className="relative group">
        <label
          htmlFor={name}
          className="
            absolute 
            -top-3 
            left-3 
            bg-white 
            px-1 
            text-xs 
            text-gray-500 
            transition-all 
            group-focus-within:text-blue-600
          "
        >
          {label}
        </label>
        <Field
          type={type}
          name={name}
          {...props}
          onBlur={(e) => {
            if (props.onBlur) props.onBlur(e);
            const formik = props.formik;
            handleMobileAutofill(e, formik);
          }}
          className="
            w-full 
            px-4 
            py-3 
            border 
            border-gray-300 
            rounded-lg 
            focus:outline-none 
            focus:ring-2 
            focus:ring-blue-500 
            focus:border-transparent 
            transition-all 
            duration-300 
            group-focus-within:border-blue-500
            group-focus-within:shadow-md
            group-focus-within:shadow-blue-100
          "
        />
        <ErrorMessage
          name={name}
          component="div"
          className="
            text-red-500 
            text-xs 
            mt-1 
            pl-2 
            animate-slide-in
          "
        />
      </div>
    );
  };
  const FormSelect = ({ name, label, options, ...props }) => {
    return (
      <div className="relative group">
        <label
          htmlFor={name}
          className="
            absolute 
            -top-3 
            left-3 
            bg-white 
            px-1 
            text-xs 
            text-gray-500 
            transition-all 
            group-focus-within:text-blue-600
          "
        >
          {label}
        </label>
        <Field
          as="select"
          name={name}
          {...props}
          className="
            w-full 
            px-4 
            py-3 
            border 
            border-gray-300 
            rounded-lg 
            focus:outline-none 
            focus:ring-2 
            focus:ring-blue-500 
            focus:border-transparent 
            transition-all 
            duration-300 
            group-focus-within:border-blue-500
            group-focus-within:shadow-md
            group-focus-within:shadow-blue-100
          "
        >
          <option value="" disabled>
            Select {label}
          </option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Field>
        <ErrorMessage
          name={name}
          component="div"
          className="
            text-red-500 
            text-xs 
            mt-1 
            pl-2 
            animate-slide-in
          "
        />
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-white to-blue-100 flex items-center justify-center p-6">
      <div
        className="
        w-full 
        max-w-5xl 
        bg-white 
        rounded-3xl 
        shadow-2xl 
        overflow-hidden 
        border 
        border-gray-100
        transform 
        transition-all 
        hover:scale-[1.01]
      "
      >
        {/* Elegant Header */}
        <div
          className="
          bg-gradient-to-r 
          from-blue-600 
          via-indigo-700 
          to-purple-800 
          text-white 
          p-8 
          shadow-lg
        "
        >
          <h2
            className="
            text-4xl 
            font-extrabold 
            text-center 
            tracking-wide 
            bg-clip-text 
            text-transparent 
            bg-gradient-to-r 
            from-white 
            to-blue-200
          "
          >
            Sales Data Collection
          </h2>
          <p
            className="
            text-center 
            text-blue-100 
            mt-2 
            opacity-80
          "
          >
            Comprehensive Sales Information Tracking
          </p>
        </div>

        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {(formikProps) => (
            <Form className="p-10 space-y-8">
              <div
                className="
                bg-gray-50 
                border 
                border-gray-200 
                rounded-2xl 
                p-6 
                shadow-sm
                hover:shadow-md
                transition-all
              "
              >
                <h3
                  className="
                  text-2xl 
                  font-semibold 
                  text-blue-800 
                  mb-6 
                  pb-2 
                  border-b 
                  border-blue-200
                "
                >
                  Staff Details
                </h3>
                <div className="grid md:grid-cols-2 gap-6">
                  <FormInput name="mobile_no" label="Staff Mobile Number" type="tel" formik={formikProps} disabled={disabled} />
                  <FormInput name="agent_name" label="Staff Name" formik={formikProps} disabled={disabled} />
                  <FormInput name="state" label="Staff State" formik={formikProps} disabled={disabled} />
                  <FormInput name="district" label="Staff District" formik={formikProps} disabled={disabled} />
                </div>
              </div>

              {/* Ledger Details Section */}
              <div
                className="
                bg-gray-50 
                border 
                border-gray-200 
                rounded-2xl 
                p-6 
                shadow-sm
                hover:shadow-md
                transition-all
              "
              >
                <h3
                  className="
                  text-2xl 
                  font-semibold 
                  text-blue-800 
                  mb-6 
                  pb-2 
                  border-b 
                  border-blue-200
                "
                >
                  Party Details
                </h3>
                <div className="grid md:grid-cols-2 gap-6">
                  <FormInput name="ledger_mobileno" label="Party Mobile Number" type="tel" formik={formikProps} disabled={disabled} />
                  <FormInput name="contact_person" label="Party Contact Person" formik={formikProps} disabled={disabled} />
                  <FormInput name="ledger_name" label="Party Name" formik={formikProps} disabled={disabled} />
                  <FormInput name="address" label="Party Address" formik={formikProps} disabled={disabled} />
                  <FormInput name="pin_code" label="Party Pin Code" type="tel" formik={formikProps} disabled={disabled} />
                  <FormSelect
                    disabled={disabled}
                    name="fac_type"
                    label="Facility Type"
                    options={[
                      { value: "Retailer", label: "Retailer" },
                      { value: "Hospital", label: "Hospital" },
                      { value: "Doctor", label: "Doctor" },
                      { value: "Nursing Home", label: "Nursing Home" },
                    ]}
                    formik={formikProps}
                  />
                  <FormInput name="ledger_district" label="Party District" formik={formikProps} disabled={disabled} />
                  <FormInput name="ledger_state" label="Party State" formik={formikProps} disabled={disabled} />
                </div>
              </div>

              {/* Sales Details Section */}
              <div
                className="
                bg-gray-50 
                border 
                border-gray-200 
                rounded-2xl 
                p-6 
                shadow-sm
                hover:shadow-md
                transition-all
              "
              >
                <h3
                  className="
                  text-2xl 
                  font-semibold 
                  text-blue-800 
                  mb-6 
                  pb-2 
                  border-b 
                  border-blue-200
                "
                >
                  Sales Details
                </h3>
                <div className="grid md:grid-cols-2 gap-6">
                  <FormInput name="sale_date" label="Sale Date" type="date" formik={formikProps} />
                  <FormInput name="visits" label="Visits" type="number" formik={formikProps} />
                  <FormInput name="pob" label="POB" type="number" formik={formikProps} />
                  <FormInput name="secondary_sale" label="Secondary Sale" type="number" formik={formikProps} />
                </div>
              </div>

              {/* Submit Button */}
              <div className="text-center">
                <button
                  type="submit"
                  disabled={!formikProps.isValid || !formikProps.dirty || isSubmitting}
                  className="
                    px-10 
                    py-4 
                    bg-gradient-to-r 
                    from-blue-600 
                    via-indigo-700 
                    to-purple-800 
                    text-white 
                    rounded-xl 
                    text-lg 
                    font-semibold 
                    hover:from-blue-700 
                    hover:via-indigo-800 
                    hover:to-purple-900 
                    transition-all 
                    duration-300 
                    shadow-xl 
                    hover:shadow-2xl 
                    focus:outline-none 
                    focus:ring-4 
                    focus:ring-blue-500 
                    focus:ring-opacity-50
                    disabled:opacity-50
                    disabled:cursor-not-allowed
                    transform 
                    hover:scale-105
                  "
                >
                  {isSubmitting ? "Submitting..." : "Submit Sales Data"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SalesForm;
